<template>
    <div v-if="!loading">
        <Header :title="boardData.title" :backToAction="true"/>
        <div class="h-ful border-b">
            <div class="px-8 bg-white">
                <div class="border-b">
                    <DataTableSearch :borderTop="false" @handleSearchTable="handleSearchTable"/>
                </div>
                <Listings
                    :listingsArr="searchedListings"
                    @toggleOpen="toggleOpen"
                    :isSearched="isSearched"
                    :isPublicRoute="true"
                    @handleSort="handleSort"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
import AccordingItem from '@/components/Board/AccordingItem/AccordingItem';
import draggable from 'vuedraggable';
import Listings from '@/components/Board/Listings/Listings';
import { withListings } from '@/mixins/Listings/withListings';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import Header from '../../../components/PublicPages/Header';

export default {
    name: 'PublicListings',
    mixins: [withListings],
    components: {
        Header,
        MainButton,
        Listings,
        AccordingItem,
        DataTableSearch,
        draggable
    },
    data() {
        return {
            boardData: {},
            copyBoardData: {},
            listings: [],
            dataTable: [],
            loading: true,
            isSearched: false,
            searchValue: ''
        };
    },
    computed: {
        ...mapGetters({
            getBoardListings: 'getPublicListings'
        }),
        searchedListings() {
          return this.listings.map((listing) => {
            const listing_ = { ...listing };
            listing_.tasks = listing_.tasks.filter((task) => {
              if (
                  task.title.toLowerCase().includes(this.searchValue)
                    || task.customer?.toLowerCase().includes(this.searchValue)
                    || task.members?.filter((member) => member.title.toLowerCase().includes(this.searchValue)).length
              ) {
                return task;
              }
            });

            listing_.tasksCount = listing_.tasks.length;
            return listing_;
          });
        }
    },
    watch: {
        getBoardListings(newValue) {
            this.boardData = newValue;
            this.loading = false;
        },
        boardData({ listings }) {
            this.listings = listings;
        }
    },
    created() {
        this.handleGetPublicListings({
            uuid: this.$route.params.uuid,
            boardUuid: this.$route.params.boardUuid
        });
    },
    methods: {
        ...mapActions({
            handleGetPublicListings: 'fetchPublicListings'
        }),
        toggleOpen() {
            this.isSearched = false;
        },
        handleSearchTable(value) {
          this.isSearched = true;
          this.searchValue = value;
        },
        handleSort(key, sorting) {
          this.handleGetPublicListings({
            uuid: this.$route.params.uuid,
            boardUuid: this.$route.params.boardUuid,
            sortBy: key,
            sortDesc: +sorting
          });
        }
    }
};
</script>

<style scoped>

</style>
